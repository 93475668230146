import React from 'react'
import banana from 'assets/svg/walking-banana.svg'

export default function NotFoundPage(props) {
  return (
    <div className="h-screen container py-10 flex flex-col items-center justify-center">
      <img
        src={banana}
        className="w-24"
      />
      <h1 className="text-2xl sm:text-3xl md:text-4xl font-platform text-gray-900">
        {props.text || "Sorry, this page doesn't exist!"}
      </h1>
    </div>
  )
}
